import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'


const mapStateToProps = ({ locales }) => ({ locales })

const Localization = ({ children, locales: { availableLocales, selectedLocale } }) => {
  const currentLocale = availableLocales[selectedLocale].associatedLocale
  return (
    <ConfigProvider locale={currentLocale.localeAntd}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export default connect(mapStateToProps)(Localization)
