import React from 'react'
import { Modal, Row, Col, Tooltip } from 'antd'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import Iframe from 'react-iframe'
import actions from 'redux/virtual/event/actions'

import './socialwall.less'

const mapStateToProps = ({ virtualEvent,virtualUser }) => ({ virtualEvent,virtualUser })

@connect(mapStateToProps)
class WhereBy extends React.Component {
  handleClose = () => {
    const { dispatch,virtualEvent: { assignedTable, assignedChair} } = this.props
    dispatch({
      type: actions.SET_STATE,
      payload: {
        isWhereByMeetOpen: false,
        whereByMeetURL: '', 
        whereByMeetHostURL: ''
      },
    })
    if(assignedTable !== '' && assignedChair !== '')
    {
      dispatch({
        type: actions.LEAVE_CHAIR,
      })
    }
  }

  render() {
    const {
      virtualEvent: { isWhereByMeetOpen, whereByMeetURL, whereByMeetHostURL,tableName },
      virtualUser: {roles, name}
    } = this.props
    return (
      <div>
        <Modal
          title={tableName}
          visible={isWhereByMeetOpen}
          style={{
            top: 0,
            display: 'flex',
          }}
          wrapClassName="wherebyModal"
          maskClosable={false}
          // maskStyle={{ marginTop: '48px' }}
          mask
          width="90vw"
          onOk={this.handleClose}
          confirmLoading
          onCancel={this.handleClose}
          footer={null}
          // closeIcon={<Tooltip title="Leave Meeting"><svg style={{marginRight: "15px", width: "40px", fill: "#f26b4d"}} width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="BaseIcon-3KAM BaseIcon--sized-2utE BaseIcon--meetingRed-3IsO" aria-hidden="true"><path clipRule="evenodd" d="m7.08412 2.9999c.37131-1.53546 1.91748-2.571085 3.54448-2.222445l8 1.714285c1.3832.2964 2.3714 1.51879 2.3714 2.93341v.57475 12 .5748c0 1.4146-.9882 2.637-2.3714 2.9334l-8 1.7142c-1.627.3487-3.17317-.6869-3.54448-2.2224h-1.08412c-1.65685 0-3-1.3431-3-3v-6.5-.5-5c0-1.65685 1.34315-3 3-3zm-.08412 2h-1c-.55228 0-1 .44772-1 1v5 .5 6.5c0 .5523.44772 1 1 1h1zm4 7c0 .5523-.4477 1-1 1-.55228 0-1-.4477-1-1s.44772-1 1-1c.5523 0 1 .4477 1 1z" fillRule="evenodd" /></svg></Tooltip>}
          closeIcon={<Tooltip title="Leave Meeting"><img src="resources/images/hangup.png" alt="hangup" style={{marginRight: "15px", width: "40px"}} /></Tooltip>}
          destroyOnClose
        >
          <Row id="modalContent" className="modalContent">
            <Scrollbars autoHeight autoHeightMin="100px" autoHeightMax="calc(100vh - 120px)">
              <Col span={24}>
                <Iframe
                  url={roles.includes('ROLE_ADMIN') ? `${whereByMeetHostURL}&displayName=${name}%20(Moderator)&leaveButton=off` : `${whereByMeetURL}?displayName=${name}&leaveButton=off`}
                  width="100%"
                  height="calc(100vh - 120px)"
                  loading="eager"
                  className="theWherebyiFrame"
                  display="initial"
                  position="relative"
                  frameborder="0"
                  allow="camera; microphone; fullscreen; speaker; display-capture"
                />
              </Col>
            </Scrollbars>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default WhereBy
