import { all, call, takeEvery, put, select } from 'redux-saga/effects'
import {
  getSavedData,
  writeData,
  writeDefault,
  getDefault,
  writeAgenda,
  getBackendAgenda,
  getExhibitors,
  getModerators,
  saveMenu,
  getMenu,
  deleteSection,
  deleteSession,
  saveSpeakers,
  getAllSpeakers,
  getRoles,
} from 'services/virtual/setup'
import { initChat, pubnubPublish } from 'services/virtual/chat'
import { uploadFile } from 'services/virtual/general'
import { message } from 'antd'
import actions from './actions'

export function* GET_INIT() {
  try {
    const setupData = yield call(getSavedData)
    if (Object.keys(setupData.data.scenes).length > 0) {
      const final = setupData.data.scenes
      yield put({
        type: actions.SET_STATE,
        payload: {
          scenes: final,
        },
      })
    }

    const roles = yield call(getRoles)
    if (roles.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          roles: roles.data,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_AGENDA() {
  try {
    const setupData = yield call(getBackendAgenda)
    if (Object.keys(setupData.data).length > 0) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          agenda: setupData.data,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_SPEAKERS() {
  try {
    const setupData = yield call(getAllSpeakers)
    if (Object.keys(setupData.data).length > 0) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          speakers: setupData.data,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SAVE_DATA(data) {
  try {
    const result = yield call(writeData, { load: data.payload })
    if (result.status === 200) {
      const final = data.payload.scenes
      yield put({
        type: actions.SET_STATE,
        payload: {
          scenes: final,
        },
      })
      message.success('DB Updated Successfully')
    } else {
      message.error('Some Error Updating the DB')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SAVE_AGENDA(data) {
  try {
    const result = yield call(writeAgenda, { load: data.payload })
    if (result.status === 200) {
      message.success('DB Updated Successfully')
    } else {
      message.error('Some Error Updating the DB')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SET_DEFAULT(data) {
  try {
    const result = yield call(writeDefault, { load: data.payload })
    if (result.status === 200) {
      const final = data.payload.id
      yield put({
        type: actions.SET_STATE,
        payload: {
          defaultScene: final,
        },
      })
      message.success('DB Updated Successfully')
    } else {
      message.error('Some Error Updating the DB')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_DEFAULT(data) {
  try {
    const defaultScene = yield call(getDefault, { source: data.payload.source })
    if (defaultScene.data.id !== '') {
      const final = defaultScene.data.id
      yield put({
        type: actions.SET_STATE,
        payload: {
          defaultScene: final,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* UPLOAD_SCENE_BG(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }
    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      yield put({
        type: actions.SET_SCENE,
        payload: {
          key: 'imageUrl',
          actualload: result.data.fileUrl,
          currentScene: data.payload.currentScene,
        },
      })

      data.payload.onSuccess('ok')
      message.success('Image Uploaded Successfully')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error('Error occured in uploading the Image.')
    }
  } catch (err) {
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* UPLOAD_FILES(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }

    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      console.log('lets see uploading')
      console.log(data.payload.file)
      const state = yield select()
      const {
        virtualSetup: { scenes, speakers },
      } = state

      // console.log('Inside File Upload')
      // console.log(result)
      // console.log(data.payload.uploadtype)
      // console.log(data)
      // console.log(
      //   data.payload.uploadtype ===
      //     ('widgetimageupload' || 'fileupload' || 'socialtableimageupload'),
      // )
      if (
        data.payload.uploadtype === 'widgetimageupload' ||
        data.payload.uploadtype === 'fileupload' ||
        data.payload.uploadtype === 'socialtableimageupload' ||
        data.payload.uploadtype === 'dollyupload' ||
        data.payload.uploadtype === 'popImageUpload' ||
        'gameicon'
      ) {
        const newState = scenes[data.payload.currentScene].clickSections
        if (data.payload.uploadtype === 'fileupload') {
          newState[data.payload.clickSectionIndex].downloads[data.payload.downloadIndex].fileURL =
            result.data.fileUrl
        } else if (data.payload.uploadtype === 'widgetimageupload') {
          newState[data.payload.clickSectionIndex].widget.imageUrl = result.data.fileUrl
        } else if (data.payload.uploadtype === 'popImageUpload') {
          newState[data.payload.clickSectionIndex].widget.widgetData.popImageURL =
            result.data.fileUrl
        } else if (data.payload.uploadtype === 'socialtableimageupload') {
          newState[data.payload.clickSectionIndex].socialtables[
            data.payload.downloadIndex
          ].tableLogo = result.data.fileUrl
        } else if (data.payload.uploadtype === 'dollyupload') {
          newState[data.payload.clickSectionIndex].dollyurl = result.data.fileUrl
        } else if (data.payload.uploadtype === 'gameicon') {
          newState[data.payload.clickSectionIndex].widget.widgetData.gameIcon = result.data.fileUrl
        }

        console.log('watching new state')
        console.log(newState[data.payload.clickSectionIndex])
        console.log(newState[data.payload.clickSectionIndex].widget.widgetData)
        console.log(newState[data.payload.clickSectionIndex].widget.widgetData.popImageURL)
        yield put({
          type: actions.SET_SCENE,
          payload: {
            key: 'clickSections',
            actualload: newState,
            currentScene: data.payload.currentScene,
          },
        })
      }
      if (data.payload.uploadtype === 'speakerupload') {
        const tempSpeakers = speakers
        console.log('inside file upload')
        console.log(data.payload)
        tempSpeakers[data.payload.clickSectionIndex].imageUrl = result.data.fileUrl

        yield put({
          type: actions.SET_STATE,
          payload: {
            speakers: tempSpeakers,
          },
        })
      }

      data.payload.onSuccess('ok')
      message.success('File Uploaded Successfully')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error('Error occured in uploading the Image.')
    }
  } catch (err) {
    console.log(err)
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* UPLOAD_SCENE_LOGO(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }

    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      const state = yield select()
      const {
        virtualSetup: { scenes },
      } = state
      const newState = scenes[data.payload.currentScene].clickSections
      newState[data.payload.clickSectionIndex].sceneDetails.sceneLogo = result.data.fileUrl
      yield put({
        type: actions.SET_SCENE,
        payload: {
          key: 'clickSections',
          actualload: newState,
          currentScene: data.payload.currentScene,
        },
      })

      data.payload.onSuccess('ok')
      message.success('File Uploaded Successfully')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error('Error occured in uploading the Image.')
    }
  } catch (err) {
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_EXHIBITORS() {
  try {
    const exhibitors = yield call(getExhibitors)
    console.log('Watching Exhibitors')
    console.log(exhibitors)
    yield put({
      type: actions.SET_STATE,
      payload: {
        exhibitors: exhibitors.data,
      },
    })
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_MODERATORS() {
  try {
    const moderators = yield call(getModerators)
    console.log('Watching moderators')
    console.log(moderators)
    yield put({
      type: actions.SET_STATE,
      payload: {
        moderators: moderators.data,
      },
    })
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SEND_NOTIFICATIONS(data) {
  try {
    const state = yield select()
    const {
      virtualSetup: { pubnub },
    } = state

    let pubnubNew = ''
    if (pubnub === null) {
      pubnubNew = yield call(initChat, 'wiz365-admin')
      yield put({
        type: actions.SET_STATE,
        payload: {
          pubnub: pubnubNew,
        },
      })
    }

    const publishData = {
      channelID: process.env.REACT_APP_NOTIFICATION_CHANNEL,
      message: data.payload.message,
      pubnub: pubnub === null ? pubnubNew : pubnub,
    }
    yield call(pubnubPublish, publishData)
    message.success('Notification Published Successfully')
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SAVE_MENU(data) {
  try {
    yield call(saveMenu, data.payload)
    message.success('Menu Saved Successfully')
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* GET_MENU(data) {
  try {
    const result = yield call(getMenu, data.payload)
    console.log('getting menu')
    console.log(result)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          menu: result.data,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* DELETE_CLICKSECTION(data) {
  try {
    const result = yield call(deleteSection, data.payload.clickSection)
    console.log(result)
    if (result.status === 200) {
      yield put({
        type: actions.SET_SCENE,
        payload: {
          key: 'clickSections',
          actualload: data.payload.allClickSections,
          currentScene: data.payload.currentScene,
        },
      })
      message.success('Section Deleted Successfully')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* DELETE_SESSION(data) {
  try {
    const result = yield call(deleteSession, data.payload.session)
    console.log(result)
    if (result.status === 200) {
      yield put({
        type: actions.SET_AGENDA,
        payload: {
          key: 'agendaItems',
          actualload: data.payload.allSessions,
          currentAgenda: data.payload.currentAgenda,
        },
      })
      message.success('Session Deleted Successfully')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SAVE_SPEAKERS(data) {
  try {
    const result = yield call(saveSpeakers, data.payload.speakers)
    console.log(result)
    if (result.status === 200) {
      message.success('Speakers Saved')
    }
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export function* SEND_FOLLOW_ME(data) {
  try {
    const state = yield select()
    const {
      virtualSetup: { pubnub },
    } = state

    let pubnubNew = ''
    if (pubnub === null) {
      pubnubNew = yield call(initChat, 'wiz365-admin')
      yield put({
        type: actions.SET_STATE,
        payload: {
          pubnub: pubnubNew,
        },
      })
    }

    const publishData = {
      channelID: process.env.REACT_APP_NOTIFICATION_CHANNEL,
      message: data.payload.message,
      pubnub: pubnub === null ? pubnubNew : pubnub,
    }
    yield call(pubnubPublish, publishData)
    message.success('People Moved to designated spot')
  } catch (err) {
    console.log(err.response)
    message.error(`${err.response.status}:${err.response.data.message}`)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INIT, GET_INIT),
    takeEvery(actions.SAVE_DATA, SAVE_DATA),
    takeEvery(actions.SET_DEFAULT, SET_DEFAULT),
    takeEvery(actions.GET_DEFAULT, GET_DEFAULT),
    takeEvery(actions.UPLOAD_SCENE_BG, UPLOAD_SCENE_BG),
    takeEvery(actions.UPLOAD_FILES, UPLOAD_FILES),
    takeEvery(actions.UPLOAD_SCENE_LOGO, UPLOAD_SCENE_LOGO),
    takeEvery(actions.SAVE_AGENDA, SAVE_AGENDA),
    takeEvery(actions.SAVE_SPEAKERS, SAVE_SPEAKERS),
    takeEvery(actions.GET_AGENDA, GET_AGENDA),
    takeEvery(actions.GET_EXHIBITORS, GET_EXHIBITORS),
    takeEvery(actions.GET_MODERATORS, GET_MODERATORS),
    takeEvery(actions.SEND_NOTIFICATIONS, SEND_NOTIFICATIONS),
    takeEvery(actions.SAVE_MENU, SAVE_MENU),
    takeEvery(actions.GET_MENU, GET_MENU),
    takeEvery(actions.DELETE_CLICKSECTION, DELETE_CLICKSECTION),
    takeEvery(actions.DELETE_SESSION, DELETE_SESSION),
    takeEvery(actions.GET_SPEAKERS, GET_SPEAKERS),
    takeEvery(actions.SEND_FOLLOW_ME, SEND_FOLLOW_ME),
  ])
}
