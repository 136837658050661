import React from 'react'
import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { notification, message } from 'antd'
import {
  login,
  logout,
  forgotPassword,
  resetPassword,
  editProfile,
  sendOTP,
  getAllRating,
  giveRating,
} from 'services/virtual/user'
import { uploadFile } from 'services/virtual/general'
import capitalizeFirstLetters from 'capitalize-first-letters'
import streamActions from 'redux/virtual/streamfeature/actions'
import stallConfigActions from 'redux/virtual/stallconfigurator/actions'
import actions from './actions'

// import eventActions from '../event/actions'
import menuActions from '../menu/actions'

export function* LOGIN(data) {
  try {
    const result = yield call(login, { finalData: data.payload })
    if (result.status === 200) {
      if (result.data.active_status) {
        if (!result.data.kick_out) {
          if (!result.data.login_status) {
            if (result.data.payment_status) {
              localStorage.setItem('acctoken', result.data.accessToken)
              localStorage.setItem('userid', result.data.id)
              localStorage.setItem('tokenType', result.data.tokenType)
              localStorage.setItem('userData', JSON.stringify(result.data.userData))
              yield put({
                type: actions.LOGIN_HELPER,
                payload: {
                  userData: result.data.userData,
                  id: result.data.id,
                  tokenType: result.data.tokenType,
                  accessToken: result.data.accessToken,
                },
              })
            } else {
              notification.error({
                message: 'Trouble with account',
                duration: 12,
                description:
                  'Your account is not yet approved. It could be because of incomplete registration process. Kindly contact the admin right now.',
              })
              localStorage.clear()
              yield put({
                type: actions.SET_STATE,
                payload: {
                  loading: false,
                },
              })
            }
          } else {
            notification.error({
              message: 'Multiple Session Detected',
              duration: 12,
              description: `Your account is logged in another device. Kindly logout from the other device to login here. If you think this is an issue, kindly contact the admin or email us at ${process.env.REACT_APP_SUPPORT_EMAIL}`,
            })
            localStorage.clear()
            yield put({
              type: actions.SET_STATE,
              payload: {
                loading: false,
              },
            })
          }
        } else {
          notification.error({
            message: 'You Account has been blocked',
            duration: 12,
            description:
              'The account has been blocked by the admin. It could be due to voilation of our policies. Kindly contact the admin now.',
          })
          localStorage.clear()
          yield put({
            type: actions.SET_STATE,
            payload: {
              loading: false,
            },
          })
        }
      } else {
        notification.error({
          message: "We haven't started yet.",
          duration: 12,
          description: `${process.env.REACT_APP_EVENT_NAME} is Scheduled for ${process.env.REACT_APP_START_DATE_TIME}. We'll see you there.`,
        })
        localStorage.clear()
        yield put({
          type: actions.SET_STATE,
          payload: {
            loading: false,
          },
        })
      }
    } else {
      notification.error({
        message: 'Status Invalid',
        duration: 12,
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })
      localStorage.clear()
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'Unauthorized Access',
        description:
          'The details entered for logging in are either incorrect or the user is not registered.',
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
        },
      })
    }
  }
}

export function* LOGIN_HELPER(data) {
  try {
    const detailResult = data.payload.userData
    const helperMessage = <p>Welcome to {process.env.REACT_APP_EVENT_NAME}</p>
    notification.success({
      message: `Hi ${capitalizeFirstLetters(detailResult.name)}`,
      duration: 8,
      description: helperMessage,
    })

    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     isTutorialVideoVisible: true,
    //   },
    // })

    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     isEditProfileOpen: true
    //   }
    // })

    console.log('testing this')
    console.log(detailResult)

    if (detailResult.roles.includes('ROLE_STALL_CONFIGURATOR')) {
      yield put({
        type: stallConfigActions.SET_STATE,
        payload: {
          isStallDesignerOpen: true,
        },
      })
    }

    yield put({
      type: actions.SET_STATE,
      payload: {
        id: data.payload.id,
        name: capitalizeFirstLetters(detailResult.name),
        number: `+${detailResult.number}`,
        roles: detailResult.roles,
        email: detailResult.email,
        organization: capitalizeFirstLetters(detailResult.organization),
        designation: capitalizeFirstLetters(detailResult.designation),
        avatarType: detailResult.avatar_type,
        avatarData: detailResult.avatar_data,
        avatarColor: detailResult.avatar_color,
        accessToken: data.payload.accessToken,
        tokenType: data.payload.tokenType,
        assignedScene: detailResult.assignedScene,
        authorized: true,
        loading: false,
      },
    })

    yield put({
      type: menuActions.GET_DATA,
    })

    // yield put({type: actions.GET_ALL_RATINGS})

    yield put({
      type: streamActions.SET_STATE,
      payload: {
        assignedSessions: detailResult.assignedSessions,
      },
    })
  } catch (err) {
    console.log(err)
    // const result = err.response
    // if (result.status === 401) {
    //   notification.error({
    //     message: 'Unauthorized Access',
    //     duration: 12,
    //     description:
    //       'The details entered for logging in are either incorrect or the user is not registered.',
    //   })

    //   yield put({
    //     type: actions.SET_STATE,
    //     payload: {
    //       loading: false,
    //     },
    //   })
    // }
  }
}

export function* LOGOUT() {
  // yield call(logout)
  localStorage.clear()
  const state = yield select()
  const {
    virtualUser: { accessToken },
  } = state

  yield call(logout, {
    token: accessToken,
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      id: '',
      name: '',
      number: '',
      roles: '',
      email: '',
      organization: '',
      designation: '',
      avatarType: '',
      avatarData: '',
      avatarColor: '',
      accessToken: '',
      tokenType: '',
      assignedScene: '',
      authorized: false,
      loading: false,
    },
  })

  window.location.href = '/'
}

export function* RESET_PASSWORD(data) {
  try {
    const result = yield call(resetPassword, {
      id: data.payload.id,
    })
    if (result.status === 200) {
      console.log(result)
      yield put({
        type: actions.SET_STATE,
        payload: {
          resetLoading: false,
        },
      })
      notification.success({
        message: 'Password Reset is now successful',
        duration: 12,
        description: 'Kindly login using the password shown on the screen.',
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'Password Reset Unsuccessful',
        duration: 12,
        description: `Kindly write to us at ${process.env.REACT_APP_SUPPORT_EMAIL}`,
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          forgotLoading: false,
        },
      })
    }
  }
}

export function* FORGOT_PASSWORD(data) {
  try {
    const result = yield call(forgotPassword, {
      userDetails: data.payload.userDetails,
    })
    if (result.status === 200) {
      console.log(result)
      yield put({
        type: actions.SET_STATE,
        payload: {
          forgotLoading: false,
        },
      })
      notification.success({
        message: 'Email Successfully sent',
        duration: 12,
        description:
          "Kindly check your email for password recovery link. Don't forget to check your inbox, promotions tab, updates tab or spam for the email.",
      })
    }
  } catch (err) {
    console.log(err.response)
    const result = err.response
    if (result.status === 401) {
      notification.error({
        message: 'User not found with this User ID / Email ID',
        duration: 12,
        description: `We could not find a user registered with mentioned Email ID / User ID. Kindly write to us at ${process.env.REACT_APP_SUPPORT_EMAIL}`,
      })

      yield put({
        type: actions.SET_STATE,
        payload: {
          forgotLoading: false,
        },
      })
    }
  }
}

export function* EDIT_AVATAR(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }
    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          avatarType: 'image',
          avatarData: result.data.fileUrl,
        },
      })
      data.payload.onSuccess('ok')
      message.success('Image Uploaded Successfully')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error(
        'Error occured in uploading the Image. Kindly Choose a normal Avatar as of now.',
      )
    }
  } catch (err) {
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(err.response.data.message)
  }
}

export function* COMPLETE_EDIT_PROFILE() {
  try {
    const state = yield select()
    const {
      virtualUser: { name, avatarType, avatarData, avatarColor, accessToken },
    } = state
    const finalValue = {
      name,
      avatarType,
      avatarData,
      avatarColor,
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        editProfileLoading: true,
      },
    })
    const result = yield call(editProfile, { finalData: finalValue, token: accessToken })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          isEditProfileOpen: false,
          editProfileLoading: false,
          isTutorialVideoVisible: true,
        },
      })
      message.success('Profile Edited Succesfuly')
    } else {
      message.error(`${result.status}: ${result.statusText} - Error Occurred`)
    }
  } catch (err) {
    console.log(err.response)
    message.error(err.response.data.message)
  }
}

export function* SEND_OTP() {
  try {
    const state = yield select()
    const {
      virtualUser: { firstName, lastName, email },
    } = state
    const result = yield call(sendOTP, { firstName, lastName, email })
    console.log('The final result')
    console.log(result)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          otpLoading: false,
          currentScreen: 1,
        },
      })
      message.success(`OTP sent successfully to ${email}`)
    } else if (result.status === '401') {
      message.error(`Issue in sending the email. Kindly recheck you email id.`)
      yield put({
        type: actions.SET_STATE,
        payload: {
          otpLoading: false,
        },
      })
    } else {
      message.error(`${result.status}: ${result.statusText} - Error Occurred`)
    }
  } catch (err) {
    message.error(`Issue with email. Retry sending the OTP.`)
    yield put({
      type: actions.SET_STATE,
      payload: {
        otpLoading: false,
      },
    })
  }
}

export function* FAKE_LOGOUT() {
  // yield call(logout)
  const state = yield select()
  const {
    virtualUser: { accessToken },
  } = state

  yield call(logout, {
    token: accessToken,
  })
}

export function* GET_ALL_RATINGS() {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken },
    } = state

    const result = yield call(getAllRating, { token: accessToken })
    console.log('checking all ratings')
    console.log(result)
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          allRatings: result.data,
        },
      })
    }
  } catch (err) {
    console.log(err)
  }
}

export function* GIVE_RATING(data) {
  try {
    const state = yield select()
    const {
      virtualUser: { accessToken, allRatings },
    } = state

    const tempAllRatings = allRatings
    if (Object.prototype.hasOwnProperty.call(tempAllRatings, data.payload.sceneID)) {
      tempAllRatings[data.payload.sceneID].rating = data.payload.rating
    } else {
      tempAllRatings[data.payload.sceneID] = { rating: data.payload.rating, status: false }
    }
    yield put({
      type: actions.SET_STATE,
      payload: {
        allRatings: tempAllRatings,
      },
    })

    const result = yield call(giveRating, {
      token: accessToken,
      finalData: { scene_id: data.payload.sceneID, rating: data.payload.rating },
    })
    if (result.status === 200) {
      message.success('Rating successfully submitted')
    }
  } catch (err) {
    console.log(err)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_HELPER, LOGIN_HELPER),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FAKE_LOGOUT, FAKE_LOGOUT),
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
    takeEvery(actions.EDIT_AVATAR, EDIT_AVATAR),
    takeEvery(actions.COMPLETE_EDIT_PROFILE, COMPLETE_EDIT_PROFILE),
    takeEvery(actions.SEND_OTP, SEND_OTP),
    takeEvery(actions.GET_ALL_RATINGS, GET_ALL_RATINGS),
    takeEvery(actions.GIVE_RATING, GIVE_RATING),
  ])
}
