// import french from 'locales/fr-FR'
import english from 'locales/en-US'
// import russian from 'locales/ru-RU'
// import chinese from 'locales/zh-CN'
// import japanese from 'locales/ja-JP'
import hindi from 'locales/hi-IN'
import actions from './actions'

/* eslint-disable no-unused-vars */

const initialState = {
  availableLocales: {
    'en-US': {
      name: 'English',
      icon: 'resources/images/locales/english.png',
      associatedLocale: english
    },
    // 'ja-JP': {
    //   name: 'Japanese',
    //   icon: 'resources/images/locales/japanese.png',
    //   associatedLocale: japanese
    // },
    'hi-IN': {
      name: 'Hindi',
      icon: 'resources/images/locales/hindi.png',
      associatedLocale: hindi
    },
    'gu-IN': {
      name: 'Gujrati',
      icon: 'resources/images/locales/gujrati.png',
      associatedLocale: hindi
    },
    'be-IN': {
      name: 'Bengali',
      icon: 'resources/images/locales/bengali.png',
      associatedLocale: hindi
    }
    // 'fr-FR': {
    //   name: "French",
    //   icon: "resources/images/locales/french.png",
    //   associatedLocale: french
    // },
    // 'ru-RU': {
    //   name: "Russian",
    //   icon: "resources/images/locales/russian.png",
    //   associatedLocale: russian
    // },
    // 'zh-CN': {
    //   name: "Chinese",
    //   icon: "resources/images/locales/chinese.png",
    //   associatedLocale: chinese
    // },
  },
  isLocaleSwitcher: true,
  selectedLocale: 'en-US'
}

export default function localeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
